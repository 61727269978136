import React from 'react';
import { Metadata } from '@personalaccounts';
import AccountTabs from '../components/AccountTabs';

const PersonalBankAccount = () => {
  return (
    <>
      <Metadata statusBarColor="#FCF8E5" />
      <AccountTabs defaultTab={0} />;
    </>
  );
};

export default PersonalBankAccount;
